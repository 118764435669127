import React from "react";
import "../../assets/scss/aboutUs.scss";
import AboutUsHeader from "./AboutUsHeader";
import AboutUsIntro from "./AboutUsIntro";
import Methodologies from "./Methodologies";
import DataStats from "./DataStats";
import OurMission from "./OurMission";
import OurValue from "./OurValue";
import WhyUs from "./WhyUs";
import IndexNavbar from "components/IndexNavbar";
import DarkFooter from "components/DarkFooter";

import ScrollToTop from "react-scroll-to-top";
import { FaAngleUp } from "react-icons/fa";


const AboutUs = () => {

    return (
        <>
            <IndexNavbar />
            <div className="aboutUs">
                <AboutUsHeader />
                <AboutUsIntro />
                <Methodologies />
                <DataStats />
                <OurMission />
                <OurValue />
                <WhyUs />
            </div>
            <DarkFooter />
            <ScrollToTop smooth className="backToTopBtn" style={{ background: "linear-gradient(180deg, #1F4C8E -113%, #001550 190.5%)" }} component={<div><FaAngleUp className="scrollTopIcon" color="#fff" /></div>} />
        </>
    );
};

export default AboutUs;
