import React from "react";
import "../../assets/scss/contactUs.scss";
import ContactUsHeader from "./ContactUsHeader";
import ContactUsForm from "./ContactUsForm";
import IndexNavbar from "components/IndexNavbar";
import DarkFooter from "components/DarkFooter";

import ScrollToTop from "react-scroll-to-top";
import { FaAngleUp } from "react-icons/fa";

const ContactUs = () => {

    return (
        <>
            <IndexNavbar />
            <div className="contactUs">
                <ContactUsHeader />
                <ContactUsForm />
            </div>
            <DarkFooter />
            <ScrollToTop smooth className="backToTopBtn" style={{ background: "linear-gradient(180deg, #1F4C8E -113%, #001550 190.5%)" }} component={<div><FaAngleUp className="scrollTopIcon" color="#fff" /></div>} />
        </>
    );
};

export default ContactUs;
