import React from "react";
import "../../assets/scss/tradingView.scss";
import TradingViewHeader from "./TradingViewHeader";
import TradingPlatform from "./TradingPlatform";
import IndexNavbar from "components/IndexNavbar";
import DarkFooter from "components/DarkFooter";
import ScrollToTop from "react-scroll-to-top";
import { FaAngleUp } from "react-icons/fa";

const TradingView = () => {

    return (
        <>
            <IndexNavbar />
            <div className="tradingView">
                <TradingViewHeader />
                <TradingPlatform />
            </div>
            <DarkFooter />
            <ScrollToTop smooth className="backToTopBtn" style={{ background: "linear-gradient(180deg, #1F4C8E -113%, #001550 190.5%)" }} component={<div><FaAngleUp className="scrollTopIcon" color="#fff" /></div>} />
        </>
    );
};

export default TradingView;
