import React from "react";
import "../../assets/scss/howToInvest.scss";
import IndexNavbar from "components/IndexNavbar";
import DarkFooter from "components/DarkFooter";
import InvestHeader from "./InvestHeader";
import InvestRoadmap from "./InvestRoadmap";

import ScrollToTop from "react-scroll-to-top";
import { FaAngleUp } from "react-icons/fa";

const HowToInvest = () => {

    return (
        <>
            <IndexNavbar />
            <div className="howToInvest">
                <InvestHeader />
                <InvestRoadmap />
            </div>
            <DarkFooter />
            <ScrollToTop smooth className="backToTopBtn" style={{ background: "linear-gradient(180deg, #1F4C8E -113%, #001550 190.5%)" }} component={<div><FaAngleUp className="scrollTopIcon" color="#fff" /></div>} />
        </>
    );
};

export default HowToInvest;
