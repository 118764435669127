import React from "react";
import "../../assets/scss/ourStrategies.scss";
import OurStrategiesHeader from "./OurStrategiesHeader";
import OurStrategiesType from "./OurStrategiesType";
import IndexNavbar from "components/IndexNavbar";
import DarkFooter from "components/DarkFooter";
import ScrollToTop from "react-scroll-to-top";
import { FaAngleUp } from "react-icons/fa";

const OurStrategies = () => {

    return (
        <>
            <IndexNavbar />
            <div className="ourStrategies">
                <OurStrategiesHeader />
                <OurStrategiesType />
            </div>
            <DarkFooter />
            <ScrollToTop smooth className="backToTopBtn" style={{ background: "linear-gradient(180deg, #1F4C8E -113%, #001550 190.5%)" }} component={<div><FaAngleUp className="scrollTopIcon" color="#fff" /></div>} />
        </>
    );
};

export default OurStrategies;
