import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import "../assets/scss/navBar.scss";
import { connect, useDispatch } from 'react-redux';
import { translate } from 'utils/translate';

// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Col,
  Row,
} from "reactstrap";
import "../assets/scss/navBar.scss";
import { FaAngleDown, FaArrowDown, FaArrowRight, FaChevronDown, FaChevronRight, FaGlobe } from "react-icons/fa";

const submenu = [
  { title: 'Our Strategies', path: '/our-strategies', parent: 'trading', child: false },
  { title: 'GoldWave', path: '/gold-wave', parent: 'trading', child: false },
  { title: 'Market Diversity', path: '/market-diversity', parent: 'trading', child: false },
  { title: 'Resource', path: '/resource', parent: 'resource', child: false },
  { title: 'Trading View', path: '/trading-view', parent: 'resource', child: false },
  { title: 'How To Invest', path: '/how-to-invest', parent: 'resource', child: false },
]

function IndexNavbar(props) {
  const location = useLocation();
  const history = useHistory();
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [menu, setMenu] = React.useState('');
  const [childMenu, setChildMenu] = React.useState('');
  const [checked, setChecked] = React.useState(false);
  const [path, setPath] = React.useState('/');
  const dispatch = useDispatch();

  useEffect(() => {
    setPath(location.pathname);
    selectLanguageMobile();
  }, [location]);

  const handleChange = (e) => {
    setChecked(e.target.checked)
    if (!e.target.checked) {
      setMenu('');
    }
  }

  const selectMenuMobile = (parent) => {
    if (parent === menu) {
      setMenu('');
      setChildMenu('');
    } else {
      setMenu(parent);
      setChildMenu('');
    }
  }

  const selectChildMenuMobile = (child) => {
    if (child === childMenu) {
      setChildMenu('');
    } else {
      setChildMenu(child);
    }
  }

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 30 ||
        document.body.scrollTop > 30
      ) {
        setNavbarColor("navbar-quantbridge");
      } else if (
        document.documentElement.scrollTop < 30 ||
        document.body.scrollTop < 30
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  const selectLanguageMobile = (item, label) => {
    dispatch({
      type: 'UPDATE_LANGUAGE',
      lang: item
    });
    localStorage.setItem('qbweb-lang', 'EN');
  }


  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="lg" color={'black'}>
        <div className="navbar-translate d-flex justify-content-between align-items-center">
          <NavbarBrand href="/" id="navbar-brand" >
            <img src={require('../assets/img/logo/logo.png').default} className="img-fluid logo-header" alt="logo" />
          </NavbarBrand>

          <div className="align-items-center justify-content-center d-block d-lg-none">
            <div className="d-flex justify-content-center align-items-center hamburger-container">
              <div>
                <input id="hamburger1" className="hamburger" type="checkbox" />
                <label className={`hamburger mb-0 dark`} htmlFor="hamburger1">
                  <i className={`dark`}></i>
                </label>
                <section className={`drawer-list`}>
                  <Container className="position-relative">
                    <Row className="justify-content-start align-items-start mt-xl-10vh">
                      <Col lg={6} xs={6}>
                        <ul>
                          <li className="mb-0">
                            <a
                              className={`btn-login my-0 text-center`}
                              href="https://client.quantbridge.io/login"
                              // target="_blank"
                            >
                              {translate(props.lang, "Login")}
                            </a>
                          </li>
                        </ul>

                      </Col>
                      <Col lg={6} xs={6}>
                        <ul>
                          <li className="mb-0">
                            <Button
                              className={`nav-link btn-register text-center px-3 py-2 my-0 `}
                              href="https://client.quantbridge.io/register"
                              // target="_blank"
                            >
                              {translate(props.lang, "Open an account")}
                            </Button>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                    <Row className="mt-4 justify-content-center drawer-list-inner">
                      <Col lg={4} md={5} sm={12} xs={12}>
                        <ul className="mobile-menu-list">
                          <li>
                            <a href="/home" className={`text-left font-600 font-16 `}>{translate(props.lang, "Homepage")}</a>
                          </li>
                          <li>
                            <a href="/about-us" className={`text-left font-600 font-16 `}>{translate(props.lang, "About Us")}</a>
                          </li>
                          <li>
                            <a href="/our-partner" className={`text-left font-600 font-16 `}>{translate(props.lang, "Our Partner")}</a>
                          </li>
                          <li className="dropdown-mobile">
                            <a onClick={() => { selectMenuMobile('platforms'); selectChildMenuMobile(''); }} className={`text-left font-600 font-16  ${menu === 'platforms' ? "active" : ""}`}>{translate(props.lang, "Trading")} <FaArrowDown className="pull-right" /></a>
                            <ul className={`dropdown-mobile-menu dropdown-mobile-intial level-1 list-1 ${menu === 'platforms' ? "" : 'panel-collapse collapse'}`}>
                              <li>
                                <a href="/our-strategies" className={`text-left font-600 font-16 `}>{translate(props.lang, "Our Strategies")} <FaArrowRight className="pull-right" /></a>
                              </li>
                              <li>
                                <a href="/gold-wave" className={`text-left font-600 font-16 `}>{translate(props.lang, "GoldWave")} <FaArrowRight className="pull-right" /></a>
                              </li>
                              <li>
                                <a href="/market-diversity" className={`text-left font-600 font-16 `}>{translate(props.lang, "Market Diversity")} <FaArrowRight className="pull-right" /></a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown-mobile">
                            <a onClick={() => { selectMenuMobile('tools'); selectChildMenuMobile(''); }} className={`text-left font-600 font-16  ${menu === 'tools' ? "active" : ""}`}>{translate(props.lang, "Resource")} <FaArrowDown className="pull-right" /></a>
                            <ul className={`dropdown-mobile-menu dropdown-mobile-intial level-1 list-1 ${menu === 'tools' ? "" : 'panel-collapse collapse'}`}>
                              <li>
                                <a href="/resource" className={`text-left font-600 font-16 `}>{translate(props.lang, "Resource")} <FaArrowRight className="pull-right" /></a>
                              </li>
                              <li>
                                <a href="/trading-view" className={`text-left font-600 font-16 `}>{translate(props.lang, "Trading View")} <FaArrowRight className="pull-right" /></a>
                              </li>
                              <li>
                                <a href="/how-to-invest" className={`text-left font-600 font-16 `}>{translate(props.lang, "How to Invest")} <FaArrowRight className="pull-right" /></a>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <a href="/our-team" className={`text-left font-600 font-16 `}>{translate(props.lang, "Our Team")}</a>
                          </li>
                          <li>
                            <a href="/our-career" className={`text-left font-600 font-16 `}>{translate(props.lang, "Our Careers")}</a>
                          </li>
                          <li>
                            <a href="/contact-us" className={`text-left font-600 font-16 `}>{translate(props.lang, "Contact Us")}</a>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </Container>
                </section>
              </div>
            </div>
          </div>
        </div>
        <Collapse className="justify-content-end" isOpen={collapseOpen} navbar>
          <Nav navbar>
            <NavItem>
              <Button
                className={`nav-link btn-login text-center px-3 py-2 `}
                href="https://client.quantbridge.io/login"
                // target="_blank"
              >
                {translate(props.lang, "Login")}
              </Button>
            </NavItem>
            <NavItem>
              <Button
                className={`nav-link btn-register text-center px-3 py-2 `}
                href="https://client.quantbridge.io/register"
                // target="_blank"
              >
                {translate(props.lang, "Open an account")}
              </Button>
            </NavItem>
          </Nav>
        </Collapse>
        {
          window.innerWidth > 992 ?
            <div className="align-items-center justify-content-center mx-3 d-none d-lg-block">
              <div className="d-flex justify-content-center align-items-center hamburger-container">
                <div>
                  <input id="hamburger" className="hamburger" type="checkbox" checked={checked} onChange={handleChange} />
                  <label className={`hamburger mb-0 `} htmlFor="hamburger">
                    <i className={`${path === '/' ? "homeHamburger" : ""} ${checked ? 'checked' : 'unchecked'} `}></i>
                  </label>
                  <section className={`drawer-list`}>
                    <Row className="justify-content-center mt-xl-5vh position-relative">
                      <div className="menuNavBarTextPos">
                        <span className="menuNavBarText">{translate(props.lang, "MENU")}</span>
                      </div>
                      <Col lg={3} md={3} sm={12} xs={12}>
                        <ul className="drawer-list-group">
                          <li onMouseEnter={() => { setMenu('home'); setChildMenu(''); }} className="position-relative"><div className="hoverEffectNavText" /><a href="/homepage" className={`text-left pt-lg-4 font-weight-600 font-25 text-capitalize `}>{translate(props.lang, "Homepage")}</a></li>
                          <li onMouseEnter={() => { setMenu('aboutUs'); setChildMenu(''); }} className="position-relative"><div className="hoverEffectNavText" /><a href="/about-us" className={`text-left pt-lg-4 font-weight-600 font-25 text-capitalize `}>{translate(props.lang, "About Us")}</a></li>
                          <li onMouseEnter={() => { setMenu('ourPartner'); setChildMenu(''); }} className="position-relative"><div className="hoverEffectNavText" /><a href="/our-partner" className={`text-left pt-lg-4 font-weight-600 font-25 text-capitalize `}>{translate(props.lang, "Our Partner")}</a></li>
                          <li onMouseEnter={() => { setMenu('trading'); setChildMenu(''); }} className="position-relative"><div className="hoverEffectNavText" /><a className={`text-left pt-lg-4 font-weight-600 font-25 text-capitalize `}>{translate(props.lang, "Trading")} <FaArrowRight color={props.theme === 'dark' ? '#ffffff' : '#575757'} className="pull-right" /></a></li>
                          <li onMouseEnter={() => { setMenu('resource'); setChildMenu(''); }} className="position-relative"><div className="hoverEffectNavText" /><a className={`text-left pt-lg-4 font-weight-600 font-25 text-capitalize `}>{translate(props.lang, "Resource")} <FaArrowRight color={props.theme === 'dark' ? '#ffffff' : '#575757'} className="pull-right" /></a></li>
                          <li onMouseEnter={() => { setMenu('ourTeam'); setChildMenu(''); }} className="position-relative"><div className="hoverEffectNavText" /><a href="/our-team" className={`text-left pt-lg-4 font-weight-600 font-25 text-capitalize `}>{translate(props.lang, "Our Team")}</a></li>
                          <li onMouseEnter={() => { setMenu('ourCareers'); setChildMenu(''); }} className="position-relative"><div className="hoverEffectNavText" /><a href="/our-career" className={`text-left pt-lg-4 font-weight-600 font-25 text-capitalize `}>{translate(props.lang, "Our Careers")}</a></li>
                          <li onMouseEnter={() => { setMenu('contactUs'); setChildMenu(''); }} className="position-relative"><div className="hoverEffectNavText" /><a href="/contact-us" className={`text-left pt-lg-4 font-weight-600 font-25 text-capitalize `}>{translate(props.lang, "Contact Us")}</a></li>
                        </ul>
                      </Col>
                      <Col lg={3} md={3} sm={12} xs={12}>
                        <ul className="drawer-list-group">
                          {menu ? (
                            submenu.filter(i => i.parent === menu).map((item, j) => (
                              !item.child ? (
                                <li key={j} onMouseEnter={() => setChildMenu('')} className="position-relative"><div className="hoverEffectNavText" /><a href={item.path} className={`text-left pt-lg-4 font-weight-600 font-25 text-capitalize `}>{translate(props.lang, item.title)}</a></li>
                              ) : (
                                <li key={j} onMouseEnter={() => setChildMenu(item.path)} className="position-relative"><div className="hoverEffectNavText" /><a className={`text-left pt-lg-4 font-weight-600 font-25 text-capitalize `}>{translate(props.lang, item.title)} <FaArrowRight color={props.theme === 'dark' ? '#ffffff' : '#575757'} className="pull-right" /></a></li>
                              )
                            ))
                          ) : null}
                        </ul>
                      </Col>
                      <Col lg={3} md={3} sm={12} xs={12}>
                        <ul className="drawer-list-group">
                          {childMenu ? (
                            submenu.filter(i => i.parent === childMenu).map((item, j) => (
                              <li key={j}><a href={item.path} className={`text-left pt-lg-4 font-weight-600 font-25 text-capitalize `}>{translate(props.lang, item.title)}</a></li>
                            ))
                          ) : null}
                        </ul>
                      </Col>
                    </Row>
                  </section>
                </div>
              </div>
            </div> : null
        }
      </Navbar>
    </>
  );
}

const mapStateToProps = state => {
  const { i18n } = state;
  return {
    lang: i18n.lang
  }
}
export default connect(mapStateToProps)(IndexNavbar);