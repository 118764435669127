import React from "react";
import "../../assets/scss/marketDiversity.scss";
import MarketDivHeader from "./MarketDivHeader";
import MarketType from "./MarketType";
import IndexNavbar from "components/IndexNavbar";
import DarkFooter from "components/DarkFooter";
import ScrollToTop from "react-scroll-to-top";
import { FaAngleUp } from "react-icons/fa";

const MarketDiversity = () => {

    return (
        <>
            <IndexNavbar />
            <div className="marketDiversity">
                <MarketDivHeader />
                <MarketType />
            </div>
            <DarkFooter />
            <ScrollToTop smooth className="backToTopBtn" style={{ background: "linear-gradient(180deg, #1F4C8E -113%, #001550 190.5%)" }} component={<div><FaAngleUp className="scrollTopIcon" color="#fff" /></div>} />
        </>
    );
};

export default MarketDiversity;
