import React from "react";
import "../../assets/scss/goldWave.scss";
import GoldWaveHeader from "./GoldWaveHeader";
import Advantages from "./Advantages";
import Impact from "./Impact";
import GoldWaveStrategy from "./GoldWaveStrategy";
import AdvantagesDetails from "./AdvantagesDetails";
import GoldWaveIntro from "./GoldWaveIntro";
import IndexNavbar from "components/IndexNavbar";
import DarkFooter from "components/DarkFooter";
import ScrollToTop from "react-scroll-to-top";
import { FaAngleUp } from "react-icons/fa";


const GoldWave = () => {

    return (
        <>
            <IndexNavbar />
            <div className="goldWave">
                <GoldWaveHeader />
                <Advantages />
                <Impact />
                <GoldWaveStrategy />
                <AdvantagesDetails />
                <GoldWaveIntro />
            </div>
            <DarkFooter />
            <ScrollToTop smooth className="backToTopBtn" style={{ background: "linear-gradient(180deg, #1F4C8E -113%, #001550 190.5%)" }} component={<div><FaAngleUp className="scrollTopIcon" color="#fff" /></div>} />
        </>
    );
};

export default GoldWave;
