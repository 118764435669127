import React from "react";
import "../../assets/scss/ourPartner.scss";
import OurPartnerHeader from "./OurPartnerHeader";
import Oversight from "./Oversight";
import Certificate from "./Certificate";
import IndexNavbar from "components/IndexNavbar";
import DarkFooter from "components/DarkFooter";
import ScrollToTop from "react-scroll-to-top";
import { FaAngleUp } from "react-icons/fa";

const OurPartner = () => {

    return (
        <>
            <IndexNavbar />
            <div className="ourPartner">
                <OurPartnerHeader />
                <Oversight />
                <Certificate />
            </div>
            <DarkFooter />
            <ScrollToTop smooth className="backToTopBtn" style={{ background: "linear-gradient(180deg, #1F4C8E -113%, #001550 190.5%)" }} component={<div><FaAngleUp className="scrollTopIcon" color="#fff" /></div>} />
        </>
    );
};

export default OurPartner;
