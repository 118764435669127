import React from "react";
import "../../assets/scss/ourCareer.scss";
import OurCareerHeader from "./OurCareerHeader";
import OurCareerAdvise from "./OurCareerAdvise";
import NewsEvents from "./NewsEvents";
import IndexNavbar from "components/IndexNavbar";
import DarkFooter from "components/DarkFooter";
import ScrollToTop from "react-scroll-to-top";
import { FaAngleUp } from "react-icons/fa";

const OurCareer = () => {

    return (
        <>
            <IndexNavbar />
            <div className="ourCareer">
                <OurCareerHeader />
                <OurCareerAdvise />
                {/* <NewsEvents /> */}
            </div>
            <DarkFooter />
            <ScrollToTop smooth className="backToTopBtn" style={{ background: "linear-gradient(180deg, #1F4C8E -113%, #001550 190.5%)" }} component={<div><FaAngleUp className="scrollTopIcon" color="#fff" /></div>} />
        </>
    );
};

export default OurCareer;
